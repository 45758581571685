export interface ITaxBracket {
  income: number
  percentage: number
}

export const INCOME_TAX: ITaxBracket[] = [
  { income: 0, percentage: 0 },
  { income: 1000, percentage: 0 },
  { income: 2000, percentage: 0 },
  { income: 3000, percentage: 0 },
  { income: 4000, percentage: 0 },
  { income: 5000, percentage: 0 },
  { income: 6000, percentage: 0 },
  { income: 7000, percentage: 0 },
  { income: 8000, percentage: 0 },
  { income: 9000, percentage: 0 },
  { income: 10000, percentage: 0 },
  { income: 11000, percentage: 0 },
  { income: 12000, percentage: 0 },
  { income: 13000, percentage: 0 },
  { income: 14000, percentage: 0 },
  { income: 14000, percentage: 0.0 },
  { income: 15000, percentage: 1.5 },
  { income: 16000, percentage: 2.0 },
  { income: 17000, percentage: 2.5 },
  { income: 18000, percentage: 4.0 },
  { income: 19000, percentage: 5.5 },
  { income: 20000, percentage: 6.5 },
  { income: 21000, percentage: 7.5 },
  { income: 22000, percentage: 8.5 },
  { income: 23000, percentage: 9.5 },
  { income: 24000, percentage: 10.5 },
  { income: 25000, percentage: 11.5 },
  { income: 26000, percentage: 12.0 },
  { income: 27000, percentage: 13.0 },
  { income: 28000, percentage: 13.5 },
  { income: 29000, percentage: 14.0 },
  { income: 30000, percentage: 15.0 },
  { income: 31000, percentage: 15.5 },
  { income: 32000, percentage: 16.0 },
  { income: 33000, percentage: 16.5 },
  { income: 34000, percentage: 17.0 },
  { income: 35000, percentage: 17.5 },
  { income: 36000, percentage: 18.5 },
  { income: 37000, percentage: 19.0 },
  { income: 38000, percentage: 19.5 },
  { income: 39000, percentage: 20.0 },
  { income: 40000, percentage: 20.5 },
  { income: 41000, percentage: 21.0 },
  { income: 42000, percentage: 21.5 },
  { income: 43000, percentage: 22.0 },
  { income: 44000, percentage: 22.5 },
  { income: 45000, percentage: 22.5 },
  { income: 46000, percentage: 23.0 },
  { income: 47000, percentage: 23.5 },
  { income: 48000, percentage: 24.0 },
  { income: 49000, percentage: 24.0 },
  { income: 50000, percentage: 24.5 },
  { income: 51000, percentage: 24.5 },
  { income: 52000, percentage: 25.0 },
  { income: 53000, percentage: 25.5 },
  { income: 54000, percentage: 25.5 },
  { income: 55000, percentage: 26.0 },
  { income: 56000, percentage: 26.5 },
  { income: 57000, percentage: 26.5 },
  { income: 58000, percentage: 27.0 },
  { income: 59000, percentage: 27.0 },
  { income: 60000, percentage: 27.5 },
  { income: 61000, percentage: 28.0 },
  { income: 62000, percentage: 28.0 },
  { income: 63000, percentage: 28.5 },
  { income: 64000, percentage: 28.5 },
  { income: 65000, percentage: 29.0 },
  { income: 66000, percentage: 29.0 },
  { income: 67000, percentage: 29.0 },
  { income: 68000, percentage: 29.5 },
  { income: 69000, percentage: 29.5 },
  { income: 70000, percentage: 30.0 },
  { income: 71000, percentage: 30.0 },
  { income: 72000, percentage: 30.0 },
  { income: 73000, percentage: 30.5 },
  { income: 74000, percentage: 30.5 },
  { income: 75000, percentage: 30.5 },
  { income: 76000, percentage: 31.0 },
  { income: 77000, percentage: 31.0 },
  { income: 78000, percentage: 31.0 },
  { income: 79000, percentage: 31.5 },
  { income: 80000, percentage: 31.5 },
  { income: 81000, percentage: 31.5 },
  { income: 82000, percentage: 32.0 },
  { income: 83000, percentage: 32.0 },
  { income: 84000, percentage: 32.0 },
  { income: 85000, percentage: 32.5 },
  { income: 86000, percentage: 32.5 },
  { income: 87000, percentage: 32.5 },
  { income: 88000, percentage: 32.5 },
  { income: 89000, percentage: 33.0 },
  { income: 90000, percentage: 33.0 },
  { income: 91000, percentage: 33.0 },
  { income: 92000, percentage: 33.5 },
  { income: 93000, percentage: 33.5 },
  { income: 94000, percentage: 33.5 },
  { income: 95000, percentage: 34.0 },
  { income: 96000, percentage: 34.0 },
  { income: 97000, percentage: 34.5 },
  { income: 98000, percentage: 34.5 },
  { income: 99000, percentage: 34.5 },
  { income: 100000, percentage: 35.0 },
  { income: 101000, percentage: 35.0 },
  { income: 102000, percentage: 35.0 },
  { income: 103000, percentage: 35.5 },
  { income: 104000, percentage: 35.5 },
  { income: 105000, percentage: 35.5 },
  { income: 106000, percentage: 35.5 },
  { income: 107000, percentage: 36.0 },
  { income: 108000, percentage: 36.0 },
  { income: 109000, percentage: 36.0 },
  { income: 110000, percentage: 36.5 },
  { income: 111000, percentage: 36.5 },
  { income: 112000, percentage: 36.5 },
  { income: 113000, percentage: 36.5 },
  { income: 114000, percentage: 37.0 },
  { income: 115000, percentage: 37.0 },
  { income: 116000, percentage: 37.0 },
  { income: 117000, percentage: 37.0 },
  { income: 118000, percentage: 37.5 },
  { income: 119000, percentage: 37.5 },
  { income: 120000, percentage: 37.5 },
  { income: 121000, percentage: 37.5 },
  { income: 122000, percentage: 38.0 },
  { income: 123000, percentage: 38.0 },
  { income: 124000, percentage: 38.0 },
  { income: 125000, percentage: 38.0 },
  { income: 126000, percentage: 38.5 },
  { income: 127000, percentage: 38.5 },
  { income: 128000, percentage: 38.5 },
  { income: 129000, percentage: 38.5 },
  { income: 130000, percentage: 38.5 },
  { income: 131000, percentage: 39.0 },
  { income: 132000, percentage: 39.0 },
  { income: 133000, percentage: 39.0 },
  { income: 134000, percentage: 39.0 },
  { income: 135000, percentage: 39.0 },
  { income: 136000, percentage: 39.0 },
  { income: 137000, percentage: 39.5 },
  { income: 138000, percentage: 39.5 },
  { income: 139000, percentage: 39.5 },
  { income: 140000, percentage: 39.5 },
  { income: 141000, percentage: 39.5 },
  { income: 142000, percentage: 39.5 },
  { income: 143000, percentage: 39.5 },
  { income: 144000, percentage: 40.0 },
  { income: 145000, percentage: 40.0 },
  { income: 146000, percentage: 40.0 },
  { income: 147000, percentage: 40.0 },
  { income: 148000, percentage: 40.0 },
  { income: 149000, percentage: 40.0 },
  { income: 150000, percentage: 40.0 },
  { income: 151000, percentage: 40.0 },
  { income: 152000, percentage: 40.5 },
  { income: 153000, percentage: 40.5 },
  { income: 154000, percentage: 40.5 },
  { income: 155000, percentage: 40.5 },
  { income: 156000, percentage: 40.5 },
  { income: 157000, percentage: 40.5 },
  { income: 158000, percentage: 40.5 },
  { income: 159000, percentage: 40.5 },
  { income: 160000, percentage: 41.0 },
  { income: 161000, percentage: 41.0 },
  { income: 162000, percentage: 41.0 },
  { income: 163000, percentage: 41.0 },
  { income: 164000, percentage: 41.0 },
  { income: 165000, percentage: 41.0 },
  { income: 166000, percentage: 41.0 },
  { income: 167000, percentage: 41.0 },
  { income: 168000, percentage: 41.0 },
  { income: 169000, percentage: 41.0 },
  { income: 170000, percentage: 41.5 },
  { income: 171000, percentage: 41.5 },
  { income: 172000, percentage: 41.5 },
  { income: 173000, percentage: 41.5 },
  { income: 174000, percentage: 41.5 },
  { income: 175000, percentage: 41.5 },
  { income: 176000, percentage: 41.5 },
  { income: 177000, percentage: 41.5 },
  { income: 178000, percentage: 41.5 },
  { income: 179000, percentage: 41.5 },
  { income: 180000, percentage: 42.0 },
  { income: 181000, percentage: 42.0 },
  { income: 182000, percentage: 42.0 },
  { income: 183000, percentage: 42.0 },
  { income: 184000, percentage: 42.0 },
  { income: 185000, percentage: 42.0 },
  { income: 186000, percentage: 42.0 },
  { income: 187000, percentage: 42.0 },
  { income: 188000, percentage: 42.0 },
  { income: 189000, percentage: 42.0 },
  { income: 190000, percentage: 42.0 },
  { income: 191000, percentage: 42.0 },
  { income: 192000, percentage: 42.5 },
  { income: 193000, percentage: 42.5 },
  { income: 194000, percentage: 42.5 },
  { income: 195000, percentage: 42.5 },
  { income: 196000, percentage: 42.5 },
  { income: 197000, percentage: 42.5 },
  { income: 198000, percentage: 42.5 },
  { income: 199000, percentage: 42.5 },
  { income: 200000, percentage: 42.5 },
]
